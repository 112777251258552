<template>
    <div :class="['CEESAR-DropDown', isExpanded? 'CEESAR-DropDown-Expanded' : '']" 
            v-on-click-outside="HideContent">
            
        <div class="CEESAR-DropDownButton" v-on:click="ToggleShowContent">

            <MaterialDesign-Icon v-if="icon != undefined" :icon="icon" size="24"/>
            
            <div class="CEESAR-DropDownButtonLabel">
                {{fullLabel}}
            </div>

        </div>

        <div class="CEESAR-DropDownContentContainer">
            <div :class="['CEESAR-DropDownContent', 'CEESAR-DropDownContent-Align' + contentAlign]">
                <slot />
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: "CEESAR-DropDown",
    props:{
        label: {type: String, default: undefined},
        icon: { type: String, default: undefined },
        glyph: {type: String, default: undefined},
        emoji: {type: String, default: undefined},
        contentAlign: {type: String, default: "Left"}
    },
    data(){
        return{
            isExpanded: false
        };
    },
    computed:{
        fullLabel(){
            let label = "";
            if(this.emoji != undefined)
            {
                label = String.fromCodePoint(this.emoji) + " ";
            }
            label += this.label;
            return label;
        }
    },
    methods:{
        ToggleShowContent(e){
            this.isExpanded = !this.isExpanded;
            e.stopPropagation();
        },
        HideContent(){
            this.isExpanded = false;
        }
    }
}
</script>

<style lang="less">

</style>